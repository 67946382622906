/* eslint-disable filenames/match-regex */
import React, { useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { StringService as StringHelper } from '@rocheglobal/component-library-dot-com/src/services/string/index';
import { ExternalData } from '../../templates/external-data';
import { Topic } from '../../components/view/topic';
import { LanguageService, StoryblokService } from '../../services';
import { ExternalDataProps } from '../../templates/types';

const shouldRedirect = (
  slugifiedTag: string,
  spaceTags: Record<string, string>,
) => spaceTags[slugifiedTag] === undefined;

const TopicPage = (props: ExternalDataProps): JSX.Element => {
  const { tag } = props;
  const urlifiedTag = StringHelper.urlify(tag);
  const [unslugifiedTag, setUnslugifiedTag] = useState(undefined);
  const [translatedTag, setTranslatedTag] = useState(undefined);
  const locale = LanguageService.getActiveLanguage();

  useEffect(() => {
    const client = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });

    StoryblokService.getDatasources(client, ['tags'], { dimension: locale, cv: Date.now() })
      .then((response) => {
        const allSpaceTags: Record<string, string> = response
          .flatMap(({ data }) => data.datasource_entries)
          .reduce(
            (accumulator: Record<string, string>, entry: Record<string, string>) => ({
              ...accumulator,
              [StringHelper.urlify(entry.dimension_value || entry.value)]: entry.value,
            }),
            {},
          );

        const translations: Record<string, string> = response
          .flatMap(({ data }) => data.datasource_entries)
          .reduce(
            (accumulator: Record<string, string>, entry: Record<string, string>) => ({
              ...accumulator,
              [StringHelper.urlify(entry.dimension_value || entry.value)]:
                entry.dimension_value || entry.value,
            }),
            {},
          );

        if (shouldRedirect(urlifiedTag, allSpaceTags)) {
          window.location.replace('/404');
          return;
        }

        setTranslatedTag(translations[urlifiedTag]);
        setUnslugifiedTag(allSpaceTags[urlifiedTag]);
      });
  }, []);

  if (!unslugifiedTag || !translatedTag) {
    return null;
  }

  const extendedProps = { title: `Roche | ${translatedTag}`, ...props };
  return (
    <ExternalData>
      <Topic
        {...extendedProps}
        tag={unslugifiedTag}
        translatedTag={translatedTag}
      />
    </ExternalData>
  );
};

// eslint-disable-next-line import/no-default-export
export default TopicPage;
