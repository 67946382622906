import { TranslationService } from '@rocheglobal/component-library-dot-com/src/services/translation/index';
import { StringService as StringHelper } from '@rocheglobal/component-library-dot-com/src/services/string/index';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import StoryblokClient from 'storyblok-js-client';
import { blokToComponent, getComponent } from '..';
import { LanguageService, StoryblokService } from '../../services';
import { ExternalDataProps } from '../../templates/types';
import { SEO } from '../custom/seo';
import { FontOverride } from '../custom/font-override';

const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;

const mockedBreadcrumbs = [
  {
    name: 'topic',
    real_path: '/topic',
    slug: '/topic',
  },
];

const getMockedTeaserWall = (tag: string) => ({
  component: 'roche-teaser-wall',
  disable_filters: true,
  disable_load_more: false,
  teasers: [
    {
      tags: [tag],
      layout: 'story-teaser--variant-a',
      sorting: 'first_published_at:desc',
      component: 'roche-story-teaser-auto',
      group_tags: [],
      teaser_number: 12,
    },
  ],
});

export const Topic = (props: ExternalDataProps): JSX.Element => {
  const {
    locale, tag, translatedTag, title,
  } = props;
  const [state, setState] = useState(undefined);
  const [mockedTagList, setMockedTagList] = useState([]);

  useEffect(() => {
    const getTranslations = async (): Promise<void> => {
      const translations = {
        exploreMore: await TranslationService.translate('explore more'),
      };

      setState({ translations });
    };
    getTranslations();
  }, []);

  useEffect(() => {
    const client = new StoryblokClient({
      accessToken: StoryblokService.getConfig().options.accessToken as string,
    });

    StoryblokService.getDatasources(client, ['topic-page-tags']).then((response) => {
      const allTopicTags = response
        .flatMap(({ data }) => data.datasource_entries)
        .map((topicPageObject) => topicPageObject.value);

      setMockedTagList(allTopicTags);
    });
  }, []);

  if (!state) {
    return null;
  }

  return (
    <>
      <FontOverride overrides={props.pageContext.fontOverwrites} />
      <Helmet
        htmlAttributes={{
          class: 'theme--story',
        }}
      />
      <SEO
        authorized_roles={[]}
        lang={locale}
        slug={LanguageService.getLocaleAwareLink(`/topic/${StringHelper.urlify(translatedTag)}`, locale)}
        og_title={title}
        title={title}
      />

      <Breadcrumbs list={mockedBreadcrumbs} />

      <roche-grid>
        <roche-staging-hero
          variant="hero--variant-b"
          headline={translatedTag}
        />
      </roche-grid>

      <roche-grid>
        {blokToComponent({
          blok: getMockedTeaserWall(tag) as never,
          getComponent,
        })}
      </roche-grid>

      {mockedTagList.length > 0
        && <roche-grid color="neutral-gradient-neutral-100-neutral-400">
          <roche-headline
            level={2}
            headline={StringHelper.capitalize(state.translations?.exploreMore)}
          />
          {mockedTagList.map((item, index) => <roche-tag tag={item} key={index} />)}
        </roche-grid>
      }
    </>
  );
};
